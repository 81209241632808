import React from 'react';
import { Link } from 'gatsby'


const GetStarted = ({title, subtitle}) => {
  return (
    <section className="py-16 md:py-24">
        <div className="mx-auto text-center text-cnh-script-500">
            <h1 className="text-2xl md:text-3xl lg:text-4xl mb-5">{title}</h1>
            <h3 className="text-md md:text-lg lg:text-xl mb-12">{subtitle}</h3>
            <div className="rounded-lg bg-cnh-accent-flamingo w-32 mx-auto p-2 md:p-3">
              <Link to='/apply' className="text-base text-white" >Get Started</Link>
               </div>
        </div>
    </section>
  )
}

export default GetStarted;
