import React from "react";
import { Link } from 'gatsby';
import { Fade }  from 'react-reveal'
import { Helmet } from 'react-helmet'
import { graphql } from "gatsby"

import Layout from '../components/Layout';
import GetStarted from '../components/GetStarted';
import StudentProjects from '../components/StudentProjects';

import scratchLogo1x from '../images/scratch-logo.png';
import scratchLogo2x from '../images/scratch-logo@2x.png';
import scratchLogo3x from  '../images/scratch-logo@3x.png';
import pythonLogo1x from '../images/python-logo.png';
import pythonLogo2x from '../images/python-logo@2x.png';
import pythonLogo3x from '../images/python-logo@3x.png';
import webProgrammingLogo1x from '../images/web-programming-logo.png';
import webProgrammingLogo2x from '../images/web-programming-logo@2x.png';
import webProgrammingLogo3x from '../images/web-programming-logo@3x.png';
import Curriculum from '../images/svg/Curriculum.svg';
import Rubik from '../images/svg/Rubik.svg';
import Thinker from '../images/svg/thinker.svg';
import GlobalEdu from '../images/svg/global-edu.svg';
import RemoteVideo from '../images/svg/remote-video.svg';
import CombinedRemoteEdu from '../images/svg/combined-remote-edu.svg';

// markup
const IndexPage = ({ data }) => {

  const bgs = 4
  const [bgIndex, setBgIndex] = React.useState(0)
  const studentProjects = {
    "title": "Student Projects",
    "projects": [
      {
        "student": "Anushka",
        "grade": 6,
        "videoID": "629293239"
      },
      {
        "student": "Rahul",
        "grade": 9,
        "videoID": "615284977"
      },
      {
        "student": "Dhruv",
        "grade": 5,
        "videoID": "615284963"
      }
    ] 
  }

  React.useEffect(() => {
    const timerId = setInterval(() => {
      setBgIndex((current) => {
        return current + 1 === bgs ? 0 : current + 1        
      })
    }, bgs*1000)
    return () => clearInterval(timerId)
  }, [])
  
  return (
      
        <Layout>
          <Helmet>
            <title>{data.site.siteMetadata.title}</title>
            <meta name="description" content={data.site.siteMetadata.description} />
            <meta name="keywords" content={data.site.siteMetadata.keywords} />
            <meta property="og:title" content={data.site.siteMetadata.title} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={data.site.siteMetadata.description} />
            <meta property="og:image" content="" />
            <meta property="og:locale" content="en_us" />
            <meta property="og:site_name" content="Code N Hour" />
            <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
            <link rel="canonical" href={data.site.siteMetadata.siteUrl} />
          </Helmet>
          <section className="relative bg-cnh-brand-primary py-40 px-4">
            <div className={"absolute inset-0 overflow-hidden"}> 
                {bgIndex === 0 && <HeroBgAnimatingPattern1  />}
                {bgIndex === 1 && <HeroBgAnimatingPattern2  />}
                {bgIndex === 2 && <HeroBgAnimatingPattern3  />}
                {bgIndex === 3 && <HeroBgAnimatingPattern4  />}
            </div>
            <div
                className="relative z-10 flex flex-col items-center space-y-6 md:space-y-10 lg:space-y-12 text-white font-medium text-xl md:text-3xl lg:text-4xl text-center">
                <p className="animate-fadeIn" style={{'--beginTransform': 'translateY(20px)', '--endTransform': 'translateY(0px)'}}> 
                Bill Gates started programming when he was 13 
                </p>
                <p className="animate-fadeIn" style={{'--beginTransform': 'translateY(20px)', '--endTransform': 'translateY(0px)'}}> 
                Steve Wozniak started at 11 </p>
                <p className="animate-fadeIn" style={{'--beginTransform': 'translateY(20px)', '--endTransform': 'translateY(0px)'}}> 
                Mark Zuckerberg and Elon Musk at 10 </p>
            </div>
            <div className="absolute z-10 inset-x-0 bottom-0">
                <p className="text-white text-center text-lg md:font-medium pb-2 lg:pb-4">If your kid is following their lead,
                    we can help! </p>
            </div> 
          </section> 
          <div className="bg-white">
            <p className="p-12 text-center text-xl md:text-xl lg:text-2xl">Computer programming classes for 8 ~ 18 age group</p>
          </div>
          <Courses />
          <StudentProjects studentProjects={studentProjects} />
          <LearnFromAnywhere />
          <GetStarted title="Ready to learn programming?" subtitle="Create your free account to schedule trial class."/>
        </Layout>
  )
}

export default IndexPage;

const Courses = () => {
  return (
    <>
      <section className="flex justify-center px-4">
          <div
              className="container mb-16 max-w-screen-lg flex flex-col md:flex-row justify-center md:space-x-10 lg:space-x-14 space-y-8 md:space-y-0 ">
                  <Link to="/courses/learn-scratch"
                    className=" bg-cnh-accent-scratch_blue pointer w-full md:w-1/3 h-48 md:h-84 rounded-lg shadow-lg flex justify-center items-center transition duration-150 transform hover:scale-105">
                    <img className="p-4 object-scale-down"
                        srcset={`${scratchLogo3x} 3x, ${scratchLogo2x} 2x, ${scratchLogo1x} 1x` }
                        src={scratchLogo1x} /> 
                        </Link> 
                
                
                  <Link to="/courses/learn-web-development"
                      className="w-full md:w-1/3 h-48 md:h-84 bg-cnh-accent-web_yellow flex justify-center items-center rounded-lg shadow-lg transition duration-150 transform hover:scale-105">
                        <img className="p-4 object-scale-down"
                          srcset={`${webProgrammingLogo3x} 3x, ${webProgrammingLogo2x} 2x, ${webProgrammingLogo1x} 1x`}
                          src={webProgrammingLogo1x} /> 
                          </Link> 
                
                
                  <Link to="/courses/learn-python"
                      className="w-full md:w-1/3 h-48 md:h-84 bg-cnh-accent-python_blue flex justify-center items-center rounded-lg shadow-lg transition duration-150 transform hover:scale-105">
                      <img className="p-4 object-scale-down"
                          srcset={`${pythonLogo3x} 3x, ${pythonLogo2x} 2x, ${pythonLogo1x} 1x`}
                          src={pythonLogo1x} /> 
                      </Link> 
                
            </div>
      </section>
      <section className="flex justify-center px-4">
        <div
            className="container mb-16 max-w-screen-lg flex flex-col md:flex-row justify-center md:space-x-10 space-y-8 md:space-y-0">
            <div className="md:w-1/3 flex flex-col justify-start ml-4 rounded-lg">
                <div className="w-16 h-16 mb-6"> <Curriculum /> </div>
                <h3>Curriculum</h3>
                <p className="text-cnh-script-400 text-base">Our program is designed to stimulate amateur to advanced learners by creating hands on projects.</p>
            </div>
            <div className="md:w-1/3 flex flex-col justify-start ml-4 rounded-lg">
                <div className="w-16 h-16 mb-6"> <Rubik /> </div>
                <h3>Learning hard things should be fun</h3>
                <p className="text-cnh-script-400 text-base">We cultivate persistent attitudes of learning hard things with fun.</p>
            </div>
            <div className="md:w-1/3 flex flex-col justify-start ml-4 rounded-lg">
                <div className="w-16 h-16 mb-6"> <Thinker /></div>
                <h3>Learn how to think</h3>
                <p className="text-cnh-script-400 text-base">We teach problem solving through experience, where students learn how to extract important information, draw connections, and apply learnt concepts.</p>
            </div>
        </div>
      </section>
</> 
  )
}

const LearnFromAnywhere = () => {
  return (
    <section className="bg-cnh-feedback-zanah px-4">
        <div
            className="container mx-auto py-16 flex flex-col lg:flex-row items-center justify-center space-x-0 space-y-8 lg:space-y-0 lg:space-x-8">
            <div className="flex justify-end flex-initial lg:flex-1">
                <Fade left className="">
                  <div className="relative hidden lg:block"> 
                    <GlobalEdu className="pt-6" />
                    <RemoteVideo className="absolute w-2/5 top-0 -ml-20 mt-0" />
                  </div> 
                </Fade>
                <Fade bottom className="">
                  <CombinedRemoteEdu className="w-96 lg:hidden mx-auto" />
                </Fade>
            </div>
            <div className="lg:flex-1 flex flex-col">
              <Fade right>
                <h1>Learn from anywhere</h1>
                <p className="lg:w-3/5 text-base text-cnh-script-400">We offer live, instructor led online classes. <br/>No more driving to classes, 
                    when your child can learn from the comfort of their own home. All you need is a computer, good internet connection and a curious mind.</p>
              </Fade>
            </div>
        </div>
    </section>
  )
}

const HeroBgAnimatingPattern1 = () => {
  return (
    <div className={`hero-bg is-active`}>
      <SvgCircle />
      <SvgCircle2 />
      <SvgCircle3 />
    </div>
  )
}

const SvgCircle = () => {

  return (
    <svg
    className="text-cnh-functional-500 stroke-current"
    fill="none"
    strokeWidth={2}
    style={{
      position: "absolute",
      top: "-260px",
      left: "-100px",
      "--transform": "translate(20px, 20px) rotate(20deg)"
    }}
    width="900px"
    height="900px"
    viewBox="0 0 940 940"
  >
    <circle cx={470} cy={470} r={469} />
  </svg>
  )
}

const SvgCircle2 = () => {
  return (
    <svg
    className="text-cnh-functional-500 stroke-current"
    fill="none"
    strokeWidth={2}
    style={{
      position: "absolute",
      top: 0,
      left: 200,
      '--transform': "translate(-20px, -20px) rotate(20deg)"
    }}
    width="800px"
    height="800px"
    viewBox="0 0 940 940"
  >
    <circle cx={470} cy={470} r={469} />
  </svg>
  )
}

const SvgCircle3 = () => {
  return (
    <svg
    className="text-cnh-functional-500 stroke-current"
    fill="none"
    strokeWidth={2}
    style={{
      position: "absolute",
      top: 300,
      right: -100,
      '--transform': "translate(-20px, -20px) rotate(20deg)"
    }}
    width="400px"
    height="400px"
    viewBox="0 0 940 940"
  >
    <circle cx={470} cy={470} r={469} />
  </svg>
  )
}


/// Polygon
const HeroBgAnimatingPattern2 = () => {
  return (
    <div className={`hero-bg is-active`}>
      <svg className="text-cnh-functional-500 stroke-current" fill="none" stroke-width={2}
                style={{ 
                  position:'absolute', 
                  top:260, 
                  left:-100,  
                  '--transform': 'translate(20px, -20px)'
                 }}
                width="800px" 
                viewBox="0 0 1253 687" >
                <g id="Page-1">
                    <polygon stroke="var(--stroke)" stroke-width="2"
                        transform="translate(626.375288, 343.375288) rotate(45.000000) translate(-626.375288, -343.375288) "
                        points="384.365032 101.365032 1067.87529 -98.1247122 868.385543 585.385543 184.875288 784.875288">
                    </polygon>
                </g>
            </svg> 
            <svg className="text-cnh-functional-500 stroke-current" fill="none" stroke-width={2} 
            style={{position:'absolute', top:150, left:50,  '--transform': 'translate(40px, -40px)'}}
                width="600px" viewBox="0 0 1253 687" >
                <g id="Page-1" fill-rule="evenodd">
                    <polygon 
                        transform="translate(626.375288, 343.375288) rotate(45.000000) translate(-626.375288, -343.375288) "
                        points="384.365032 101.365032 1067.87529 -98.1247122 868.385543 585.385543 184.875288 784.875288">
                    </polygon>
                </g>
            </svg> 
            <svg className="text-cnh-functional-500 stroke-current" fill="none" stroke-width={2}
                style={{
                  position:'absolute', 
                  top:-100, 
                  right:-100,
                   '--transform': 'translate(100px, 40px)'
                  }}
                width="900px" viewBox="0 0 1253 687">
                <g id="Page-1" fill-rule="evenodd">
                    <polygon 
                        transform="translate(626.375288, 343.375288) rotate(45.000000) translate(-626.375288, -343.375288) "
                        points="384.365032 101.365032 1067.87529 -98.1247122 868.385543 585.385543 184.875288 784.875288">
                    </polygon>
                </g>
            </svg> 
    </div>
  )
}

const HeroBgAnimatingPattern3 = ({bgIndex}) => {
  return (
    <div className={`hero-bg is-active`}> 
            <svg className="text-cnh-functional-500 stroke-current" 
            fill="none" 
            stroke-width="2" 
            style={{
              position:'absolute', 
              top:-50,
              left:10,
              '--transform': 'rotate(20deg)'
            }} 
            width="2400px"
            viewBox="0 0 2400 678">
                      <g id="Page-1" fill-rule="evenodd" stroke-linecap="square">
                          <line x1="0.375" y1="676.625" x2="2400.00" y2="0.875" id="Line-5-Copy" stroke="var(--stroke)">
                          </line>
                      </g>
            </svg> 
            <svg className="text-cnh-functional-500 stroke-current" 
              fill="none" 
              stroke-width="2" 
              style={{position:'absolute', 
              top:-50,
              left:-100,
              '--transform': 'rotate(-20deg)'
            }}
                width="2400px" 
                viewBox="0 0 2400 678">
                <g id="Page-1" fill-rule="evenodd" stroke-linecap="square">
                    <line x1="0.375" y1="676.625" x2="2400.00" y2="0.875" id="Line-5-Copy" stroke="var(--stroke)">
                    </line>
                </g>
            </svg> 
            <svg className="text-cnh-functional-500 stroke-current" 
              fill="none" 
              stroke-width="2" 
              style={{position:'absolute', 
              left: -50, 
              '--transform': 'rotate(10deg)'
            }}
             width="2400px"
                viewBox="0 0 2400 678">
                <g id="Page-1" fill-rule="evenodd" stroke-linecap="square">
                    <line x1="0.375" y1="676.625" x2="2400.00" y2="0.875" id="Line-5-Copy" stroke="var(--stroke)">
                    </line>
                </g>
            </svg>
    </div> 
  )
}

const HeroBgAnimatingPattern4 = () => {
  return (
    <div className={`hero-bg is-active`} >
      <svg className="text-cnh-functional-500 stroke-current" 
        fill="none" 
        stroke-width={2}
                style={{
                  position:'absolute',
                  top:150,
                  left:10,  
                  '--transform': 'rotate(20deg)'
              }}
              width="454px"
              height="796px" 
              viewBox="0 0 454 796">
                <g id="Page-1" fill-rule="evenodd">
                    <polygon id="Path-16" points="1 505 453.5 0 393.5 795"></polygon>
                </g>
            </svg> 
            <svg className="text-cnh-functional-500 stroke-current" 
            fill="none" 
            stroke-width={2}
            style={{
              position:'absolute', 
            top:-100,
             right:100,
            '--transform': 'rotate(-20deg)'
          }}
                width="454px" height="796px" viewBox="0 0 454 796" >
                <g id="Page-1" fill-rule="evenodd">
                    <polygon id="Path-16" points="1 505 453.5 0 393.5 795"></polygon>
                </g>
            </svg> 
            <svg className="text-cnh-functional-500 stroke-current" 
            fill="none" 
            stroke-width="2"  
            style={{
              position:'absolute', 
            top:-400,
            left:10,
            '--transform': 'rotate(-10deg)'
          }}
                width="454px" height="796px" viewBox="0 0 454 796" >
                <g id="Page-1" fill-rule="evenodd">
                    <polygon id="Path-16" points="1 505 453.5 0 393.5 795"></polygon>
                </g>
            </svg>
    </div>
  )
}

export const query = graphql`
{
  site {
    siteMetadata {
      description
      keywords
      siteUrl
      title
    }
  }
}
`;


