import React from 'react';

const Video = ({videoID, videoTitle}) => ( 
  <div className="video aspect-w-16 aspect-h-9 ">
    <iframe
    className=""
      src={`https://player.vimeo.com/video/${videoID}?autoplay=1&muted=1&playsinline=1&loop=1&controls=0&autopause=0`}
      title={videoTitle}
    >
    </iframe>
  </div>
)

export default Video