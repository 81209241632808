import React from 'react';
import Video from './Video';

const StudentProjects = ({studentProjects}) => {
  return (
    <section className="py-12 md:py-16 px-10">
      <h1 className="text-cnh-script-500 text-4xl mb-2 text-center">{studentProjects.title}</h1>
      <div
          className="container flex flex-col lg:flex-row items-center justify-center space-y-10 lg:space-y-0 lg:space-x-10 py-10 mx-auto">
          {studentProjects.projects.map(project => {
            return <div
              className="relative w-full flex-1 shadow-lg transform transition duration-300 group hover:scale-110 rounded-lg overflow-hidden">
              <div
                  className="z-10 bg-cnh-functional-200 absolute bottom-0 w-full h-10 transition-opacity duration-300 opacity-0 group-hover:opacity-100">
                  <p className="text-sm text-cnh-script-400 p-2">{project.student} - Grade {project.grade}</p>
              </div>
              <Video className="z-0 relative" videoID={project.videoID} videoTitle="Test title" />
            </div>
          })}  
      </div>
    </section>
  ) 
}

export default StudentProjects